import React from 'react'

const Social_Media = () => {
    return (
        <>
            
            <div className='Service_Banner'>
                <div className='container'>
                    <h1>Social Media</h1>
                </div>
            </div>
            <div className='container mt-5 mb-4' >
                <h1 className='text-center' style={{ color: '#e7bb2a' }}>Follow Dr. Srinivas Polisetty On Social Media</h1>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <div className='' >
                            <img src='faceBook.png' alt='Facebook' style={{ width: '100%' }}></img>
                            <a href='https://www.instagram.com/dr.srinivaspolisetty/'><img src='instagramImage.jpg' style={{ width: '100%' }}></img></a>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12'>
                        <div className='' >
                            <img src='faceBook1.png' alt='Facebook' style={{ width: '100%' }}></img>
                            <a href='https://www.facebook.com/drsrinivaspolisetty/'><img src='facebook-logo2.png' className='mt-4' style={{ width: '100%' }}></img></a>
                        </div>
                    </div>

                    <div className='col-md-4 col-sm-12'>
                        <div className='' >
                            <img src='mobile mockups1.png' alt='Facebook' style={{ width: '100%' }}></img>
                            <a href='https://www.youtube.com/@dr.srinivaspolisetty6666'><img src='YouTube-Logo.jpg' className='' style={{ width: '100%' }}></img></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Social_Media